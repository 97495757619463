<template>
    <v-card>
        <v-card-title>
            {{ titleKey.title }}
        </v-card-title>
        <v-card-text style="height: 250px;">
            <Line id="my-chart-id" :options="chartOptions" :data="chartData" :key="chartData.labels.length" />
        </v-card-text>
    </v-card>
</template>

<script>
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import axios from '@/axios';

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement);

export default {
    name: 'ReportChart',
    props: {
        titleKey: {
            type: Object,
            required: true
        }
    },
    watch: {
        titleKey: {
            deep: true,
            handler() {
                this.fetchReportData();
            }
        }
    },
    components: { Line },
    data() {
        return {
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: this.titleKey.title,
                        data: [],
                        borderColor: 'rgba(0, 123, 255, 1)',
                        fill: false,
                        tension: 0.1
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                        align: 'start',
                        labels: {
                            usePointStyle: true,
                            generateLabels: (chart) => {
                                const data = chart.data;
                                const total = data.datasets[0].data.reduce((sum, value) => sum + value, 0).toFixed(2);

                                return [
                                    {
                                        text: `Total ${data.datasets[0].label}: ${total}`,
                                        fillStyle: 'rgba(0, 123, 255, 1)',
                                        hidden: false,
                                        index: 0,

                                        strokeStyle: 'transparent',
                                    },
                                ];
                            },
                        },
                    },
                    tooltip: {
                        callbacks: {
                            label: (tooltipItem) => {
                                const label = tooltipItem.dataset.label || '';
                                const value = tooltipItem.raw;
                                return `${label}: ${value}`;
                            }
                        }
                    }
                }
            },
            loading: false
        };
    },
    methods: {
        updateChartData(rawDates, rawData) {
            const formatDate = (dateString) => {
                const date = new Date(dateString);
                const options = { month: 'short', day: 'numeric' };
                return date.toLocaleDateString('en-US', options);
            };

            this.chartData.labels = rawDates.map((date) => formatDate(date));
            this.chartData.datasets[0].data = rawData;
        },
        async fetchReportData() {
            this.loading = true;
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('/report', {
                    headers: {
                        
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        page: 1,
                        itemsPerPage: 10,
                        sortBy: '',
                        sortOrder: '',
                        dimensions: ['Date'],
                        metrics: [this.titleKey.title],
                        filters: this.titleKey.filters,
                    }
                });

                if (response.status === 200) {
                    const reportData = response.data;
                    this.updateChartData(reportData.items.map(item => item.date), reportData.items.map(item => parseFloat(item[this.titleKey.key])));
                }
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                this.loading = false;
            }
        }
    },
    mounted() {
        this.fetchReportData();
    }
}
</script>

<style scoped>
.total-stats {
    margin-top: 10px;
    text-align: center;
}
</style>
