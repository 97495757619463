<template>
    <v-app-bar>
        <template v-slot:prepend>
            <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
        </template>

        <v-app-bar-title>{{ pageTitle }}</v-app-bar-title>

        <template v-slot:append>
            <v-btn icon="mdi-magnify"></v-btn>

            <v-btn icon="mdi-account" @click="changeComponent('PanelProfile', 'Profile')"></v-btn>

            <v-btn icon="mdi-exit-to-app" @click="confirmLogout"></v-btn>
            <v-dialog v-model="logoutDialog" max-width="290">
                <v-card>
                    <v-card-title class="headline">Confirm Logout</v-card-title>
                    <v-card-text>Are you sure you want to log out?</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="logout">Yes</v-btn>
                        <v-btn color="secondary" text @click="logoutDialog = false">No</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
    </v-app-bar>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
    name: "TheBar",
    data() {
        return {
            logoutDialog: false,
            pageTitle: 'Home',
        }
    },
    computed: {
        ...mapGetters(['userDetails', 'isAdmin', 'drawer']),
    },
    watch: {
        '$route.meta.title': {
            immediate: true,
            handler(newTitle) {
                this.pageTitle = newTitle || 'Default Title';
            }
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push('/');
                this.logoutDialog = false;
            });
        },
        confirmLogout() {
            this.logoutDialog = true;
        },
        ...mapActions(['toggleDrawer', 'setDrawer']),
        toggleDrawer() {
            this.setDrawer(!this.drawer);
        },
        changeComponent(component, title) {
            this.pageTitle = title;
            const routeMap = {
                'PanelProfile': '/profile',
            };
            this.$router.push(routeMap[component]);
        },
    }
}
</script>

<style>
/* Global styles */
</style>