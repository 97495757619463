<template>
  <v-col>
    <v-row class="d-flex justify-center" justify="space-around">
      <v-btn size="x-small" class="mx-1" @click="selectToday">Today</v-btn>
      <v-btn size="x-small" class="mx-1" @click="selectYesterday">Yesterday</v-btn>
      <v-btn size="x-small" class="mx-1" @click="selectThisWeek">This Week</v-btn>
      <v-btn size="x-small" class="mx-1" @click="selectThisMonth">This Month</v-btn>
      <v-btn size="x-small" class="mx-1" @click="selectThisYear">This Year</v-btn>
    </v-row>

    <v-date-picker v-model="localSelectedDates" multiple="range"></v-date-picker>
    <v-btn color="primary" @click="confirmDates">Confirm</v-btn>
    <v-btn color="secondary" @click="cancelDates">Cancel</v-btn>
  </v-col>
</template>

<script>
export default {
  name: "DateRangePicker",
  props: {
    selectedDates: {
      type: Array,
      required: true,
    },
    dateText: {
      type: String,
      required: true,
    },
    menu: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localSelectedDates: this.selectedDates,
    };
  },
  watch: {
    selectedDates: {
      handler(newVal) {
        this.localSelectedDates = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    selectToday() {
      const today = new Date();
      this.localSelectedDates = [today, today]; 
      this.updateParent();
    },
    selectYesterday() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      this.localSelectedDates = [yesterday, yesterday]; 
      this.updateParent();
    },
    selectThisWeek() {
      const today = new Date();
      const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
      const endOfWeek = new Date(today.setDate(today.getDate() + 6));
      this.localSelectedDates = [startOfWeek, endOfWeek];
      this.updateParent();
    },
    selectThisMonth() {
      const today = new Date();
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      this.localSelectedDates = [startOfMonth, endOfMonth];
      this.updateParent();
    },
    selectThisYear() {
      const today = new Date();
      const startOfYear = new Date(today.getFullYear(), 0, 1);
      const endOfYear = new Date(today.getFullYear(), 11, 31);
      this.localSelectedDates = [startOfYear, endOfYear];
      this.updateParent();
    },
    confirmDates() {
      this.updateParent();
    },
    cancelDates() {
      this.localSelectedDates = [];
      this.updateParent();
    },
    updateParent() {
      this.$emit("update:selectedDates", this.localSelectedDates);
      this.$emit("update:dateText", this.formatDateText(this.localSelectedDates));
      this.$emit("update:menu", false);
    },
    formatDateText(dates) {
      if (!dates || dates.length === 0) return '';
      dates.sort((a, b) => a - b);
      const start = dates[0];
      const end = dates[dates.length - 1];

      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      const lastMonth = new Date(today);
      lastMonth.setMonth(today.getMonth() - 1);
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay());
      const startOfMonth = new Date(today);
      startOfMonth.setDate(1);
      const startOfYear = new Date(today);
      startOfYear.setFullYear(today.getFullYear());

      if (start.toDateString() === today.toDateString()) return 'Today';
      if (start.toDateString() === yesterday.toDateString()) return 'Yesterday';
      if (start >= startOfWeek && start <= today) return 'This Week';
      if (start >= startOfMonth && start <= today) return 'This Month';
      if (start >= startOfYear && start <= today) return 'This Year';
      if (start.toDateString() === lastMonth.toDateString()) return 'Last Month';

      const startStr = start.toLocaleDateString();
      const endStr = end ? end.toLocaleDateString() : '';
      return end ? `${startStr} - ${endStr}` : startStr;
    },
  },
};
</script>

<style scoped></style>
