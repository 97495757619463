// src/axios.js
import axios from 'axios';
import store from './store';
import router from './router';

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:4000/api/' : '/api/',
});

instance.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            store.dispatch('logout');
            router.push('/');
        }
        return Promise.reject(error);
    }
);

export default instance;
