<template>
    <v-container class="pa-6">
        <v-row>
            <v-col cols="12">
                <h3>
                    <v-icon :style="currentStatusStyle" :icon="currentStatusIcon"></v-icon>
                    Your Status: <span :style="currentStatusStyle">{{ userDetails.status }}</span>
                    <br>
                    {{ currentStatusText }}
                </h3>
            </v-col>
            <v-col cols="12">
                <v-form v-model="formValid" ref="form">
                    <v-row>
                        <v-col v-for="(item, index) in formFields" :key="index" cols="12" md="6">
                            <component :is="item.component" v-model="form[item.model]" :label="item.label"
                                :rules="item.rules" :prefix="item.prefix" :items="item.items" :readonly="item.disabled"
                                required></component>
                        </v-col>
                    </v-row>
                    <v-btn :disabled="!formValid || userDetails.status !== 'new'" @click="submitRequest"
                        class="mt-4">Submit</v-btn>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from '@/axios';

export default {
    name: "MainProfile",
    data() {
        return {
            formValid: false,
            form: {
                firstName: "",
                lastName: "",
                country: "",
                language: "",
                mobile: "",
                telegramId: "",
                paymentType: "",
                appUrl: "",
                userId: ""
            },
            countries: [
                "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia",
                "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium",
                "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria",
                "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic", "Chad",
                "Chile", "China", "Colombia", "Comoros", "Congo (Congo-Brazzaville)", "Costa Rica", "Croatia", "Cuba", "Cyprus",
                "Czech Republic", "Democratic Republic of the Congo", "Denmark", "Djibouti", "Dominica", "Dominican Republic",
                "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji",
                "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala",
                "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran",
                "Iraq", "Ireland", "Israel", "Italy", "Ivory Coast", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya",
                "Kiribati", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein",
                "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
                "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco",
                "Mozambique", "Myanmar (Burma)", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger",
                "Nigeria", "North Korea", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea",
                "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis",
                "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia",
                "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands",
                "Somalia", "South Africa", "South Korea", "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden",
                "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor-Leste", "Togo", "Tonga",
                "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates",
                "United Kingdom", "United States of America", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela",
                "Vietnam", "Yemen", "Zambia", "Zimbabwe"
            ],
            languages: ["English", "French", "German", "Spanish", "Persian"],
            paymentTypes: ["Credit Card", "PayPal", "Bank Transfer"],
        };
    },
    computed: {
        ...mapGetters(['userDetails']),
        formFields() {
            const statusDisabled = this.userDetails?.status !== 'new';
            return [
                { component: 'v-text-field', model: 'firstName', label: 'First Name', rules: [v => !!v || 'First Name is required'], disabled: statusDisabled },
                { component: 'v-text-field', model: 'lastName', label: 'Last Name', rules: [v => !!v || 'Last Name is required'], disabled: statusDisabled },
                { component: 'v-select', model: 'country', label: 'Country', items: [], rules: [v => !!v || 'Country is required'], disabled: statusDisabled },
                { component: 'v-select', model: 'language', label: 'Language', items: [], rules: [v => !!v || 'Language is required'], disabled: statusDisabled },
                { component: 'v-text-field', model: 'mobile', label: 'Mobile Number', rules: [v => !!v || 'Mobile Number is required'], disabled: statusDisabled },
                { component: 'v-text-field', model: 'telegramId', label: 'Telegram ID', prefix: '@', rules: [v => !!v || 'Telegram ID is required'], disabled: statusDisabled },
                { component: 'v-select', model: 'paymentType', label: 'Payment Type', items: [], rules: [v => !!v || 'Payment Type is required'], disabled: statusDisabled },
                { component: 'v-text-field', model: 'appUrl', label: 'App URL in Google Play', rules: [v => !!v || 'App URL is required', v => /^(https:\/\/play\.google\.com\/store\/apps\/details\?id=).+/.test(v) || 'Invalid URL format'], disabled: statusDisabled }
            ];
        },
        statusOptions() {
            return [
                { status: 'Accepted', icon: 'mdi-check-circle', color: 'green', text: 'Congratulations, your application has been accepted!  You can create your own app from the apps tab.' },
                { status: 'new', icon: 'mdi-alert-decagram', color: 'blue', text: 'Fill out and submit this form to get started' },
                { status: 'Requested', icon: 'mdi-arrow-top-right-bold-box', color: 'orange', text: 'Your request will be reviewed soon and the result will be sent to you via email' },
                { status: 'Rejected', icon: 'mdi-close-circle', color: 'red', text: 'Unfortunately, you do not have the necessary conditions for cooperation!' },
            ];
        },
        currentStatus() {
            return this.userDetails?.status;
        },
        currentStatusStyle() {
            const status = this.statusOptions.find(option => option.status === this.currentStatus);
            return status ? { color: status.color } : { color: 'gray' };
        },
        currentStatusText() {
            const status = this.statusOptions.find(option => option.status === this.currentStatus);
            return status ? status.text : 'Unknown status, please check your details';
        },
        currentStatusIcon() {
            const status = this.statusOptions.find(option => option.status === this.currentStatus);
            return status ? status.icon : 'mdi-close-circle';
        }
    },
    mounted() {

        this.formFields.find(field => field.model === 'country').items = this.countries;
        this.formFields.find(field => field.model === 'language').items = this.languages;
        this.formFields.find(field => field.model === 'paymentType').items = this.paymentTypes;

        const userDetails = this.userDetails;

        if (userDetails) {
            this.form.firstName = userDetails.first_name || '';
            this.form.lastName = userDetails.last_name || '';
            this.form.country = userDetails.country || '';
            this.form.language = userDetails.language || '';
            this.form.mobile = userDetails.mobile || '';
            this.form.telegramId = userDetails.telegram_id || '';
            this.form.paymentType = userDetails.payment_type || '';
            this.form.appUrl = userDetails.app_url || '';
            this.form.userId = userDetails.id || '';
        }
    },
    methods: {
        ...mapActions(['showToast']),
        async submitRequest() {
            const userDetails = this.userDetails;
            if (this.formValid && userDetails.status === 'new') {
                try {
                    const token = localStorage.getItem('token');
                    const response = await axios.post('/submit-request', this.form, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                    });

                    if (response.status === 200) {
                        const updatedUserDetails = response.data.user;
                        this.$store.dispatch('updateUserDetails', updatedUserDetails);
                        this.showToast({ type: 'success', message: 'Request submitted successfully! refresh...' });

                        setTimeout(() => {
                            this.$router.push('/profile');
                        }, 3000);
                    }
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.showToast({ type: 'error', message: error.response.data.message });
                    } else {
                        this.showToast({ type: 'error', message: 'An error occurred. Please try again later.' });
                    }
                }
            } else {
                this.$toast.info('Please fill out the form correctly.');
            }
        }
    }
};
</script>
