// src/store/index.js
import { createStore } from 'vuex';
import toast from './toast';
import router from '../router';

export default createStore({
    state: {
        isAuthenticated: false,
        userDetails: null,
        drawer: true,
    },
    mutations: {
        setAuthenticated(state, payload) {
            state.isAuthenticated = payload.isAuthenticated;
            state.userDetails = payload.userDetails;
        },
        toggleDrawer(state) {
            state.drawer = !state.drawer;
        },
        setDrawer(state, value) {
            state.drawer = value;
        },
        updateUserDetails(state, userDetails) {
            state.userDetails = userDetails;
            localStorage.setItem('userDetails', JSON.stringify(userDetails));
        },
    },
    actions: {
        login({ commit }, { token, userDetails }) {
            commit('setAuthenticated', { isAuthenticated: true, userDetails });
            localStorage.setItem('token', token);
            localStorage.setItem('userDetails', JSON.stringify(userDetails));
        },
        logout({ commit }) {
            commit('setAuthenticated', { isAuthenticated: false, userDetails: null });
            localStorage.removeItem('token');
            localStorage.removeItem('userDetails');
            localStorage.removeItem('userPicture')
            router.push('/');
        },
        initializeStore({ commit }) {
            const token = localStorage.getItem('token');
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            if (token && userDetails) {
                commit('setAuthenticated', { isAuthenticated: true, userDetails });
            } else {
                commit('setAuthenticated', { isAuthenticated: false, userDetails: null });
            }
        },
        toggleDrawer({ commit }) {
            commit('toggleDrawer');
        },
        setDrawer({ commit }, value) {
            commit('setDrawer', value);
        },
        updateUserDetails({ commit }, userDetails) {
            commit('updateUserDetails', userDetails);
        },
        showToast(_, { type, message }) {
            switch (type) {
                case 'success':
                    toast.success(message);
                    break;
                case 'error':
                    toast.error(message);
                    break;
                case 'info':
                    toast.info(message);
                    break;
                case 'warning':
                    toast.warning(message);
                    break;
                default:
                    toast(message);
                    break;
            }
        }
    },
    getters: {
        isAuthenticated: (state) => state.isAuthenticated,
        userDetails: (state) => state.userDetails,
        drawer: (state) => state.drawer,
        isAdmin: (state) => state.userDetails?.role === 'admin',
    },
});
