<template>
    <v-app>
        <v-container>
            <v-btn @click="$router.push('/add-app')" color="primary" class="mb-4">Add App</v-btn>
            <v-card>
                <v-data-table-server v-model:items-per-page="itemsPerPage" v-model:page="currentPage" :headers="headers"
                    :items="processedItems" :items-length="totalItems" :loading="loading">
                    <template v-slot:[`item.status`]="{ item }">
                        <span :class="['blob ', getStatusClass(item.status)]"></span>
                        {{ item.status }}
                    </template>
                    <template v-slot:[`item.ad_units`]="{ item }">
                        {{ item.ad_units.join(' - ') }}
                    </template>

                </v-data-table-server>
            </v-card>
        </v-container>
    </v-app>
</template>


<script>
import axios from '@/axios';

export default {
    name: "MainApps",
    data() {
        return {
            itemsPerPage: 5,
            currentPage: 1,
            headers: [
                { title: 'Name', key: 'name' },
                { title: 'URL', key: 'url' },
                { title: 'ADS types', key: 'ad_units' },
                { title: 'Date', key: 'createdAt' },
                { title: 'Status', key: 'status' },
                { title: 'App Id', key: 'appId' }
            ],
            serverItems: [],
            loading: true,
            totalItems: 0,
            sortBy: []
        };
    },
    computed: {
        processedItems() {
            return this.serverItems.map(item => ({
                ...item,
                createdAt: this.formatDate(item.createdAt),
                adConversions: this.formatAdConversions(item.adConversions)
            }));
        }
    },
    methods: {
        async loadItems() {
            this.loading = true;

            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('/app/mylist', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        page: this.currentPage,
                        itemsPerPage: this.itemsPerPage,
                        sortBy: this.sortBy.length ? this.sortBy[0].key : '',
                        sortOrder: this.sortBy.length ? this.sortBy[0].order : ''
                    }
                });

                this.serverItems = response.data.apps;
                this.totalItems = response.data.apps.length;
            } catch (error) {
                console.error('Failed to fetch apps:', error);
            } finally {
                this.loading = false;
            }
        },
        formatDate(date) {
            const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
            return new Date(date).toLocaleDateString('en-US', options);
        },
        formatAdConversions(adConversions) {
            try {
                return JSON.parse(adConversions).join(', ');
            } catch (e) {
                return adConversions;
            }
        },
        getStatusClass(status) {
            switch (status) {
                case 'pending':
                    return 'orange';
                case 'active':
                    return 'green';
                case 'stop':
                    return 'red';
                default:
                    return '';
            }
        }
    },
    mounted() {
        this.loadItems();
    }
};
</script>


<style scoped>
.blob {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.blob.orange {
    background: rgba(255, 121, 63, 1);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
    animation: pulse-orange 2s infinite;
}

@keyframes pulse-orange {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
}

.blob.red {
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.blob.green {
    background: rgba(51, 217, 178, 1);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
    animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
}
</style>
