<!-- C:\Users\Hi\Desktop\Admob-panel\client\src\components\TheDrawNav.vue -->
<template>
    <v-navigation-drawer class="position-fixed" fixed=true theme="dark" v-model="drawer" permanent>
        <v-divider></v-divider>
        <v-list lines="two">
            <v-list-item :key="userDetails.go_email" :title="userDetails.go_name"
                :prepend-avatar="require('@/assets/userpic.png')">
            </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <v-list class="justify-center">
            <v-list-item v-for="item in navItems" :key="item.path" :to="item.path" :prepend-icon="item.icon"
                :title="item.title" :disabled="item.disabled" :active="$route.path === item.path">
                <span></span>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>

import { mapGetters } from 'vuex';
export default {
    name: "TheDrawNav",
    data() {
        return {
            logoutDialog: false,
        }
    },
    mounted() {
        const cachedPicture = localStorage.getItem('userPicture');
        if (cachedPicture) {
            this.userDetails.go_picture = cachedPicture;
        } else {
            this.fetchUserPicture();
        }
    },
    methods: {
        fetchUserPicture() {
            const pictureUrl = this.userDetails.go_picture;
            localStorage.setItem('userPicture', pictureUrl);
        }
    },
    computed: {
        ...mapGetters(['userDetails', 'isAdmin', 'drawer']),
        navItems() {
            if (!this.isAdmin) {
                return [
                    { path: '/', title: 'Home', icon: 'mdi-home', disabled: this.userDetails.status !== 'Accepted' },
                    { path: '/apps', title: 'Apps', icon: 'mdi-apps', disabled: this.userDetails.status !== 'Accepted' },
                    { path: '/reports', title: 'Reports', icon: 'mdi-chart-box', disabled: this.userDetails.status !== 'Accepted' },
                    { path: '/profile', title: 'Profile', icon: 'mdi-account', disabled: false },
                ];
            } else {
                return [
                    { path: '/ad-home', title: 'Home', icon: 'mdi-home', disabled: false },
                    { path: '/ad-apps', title: 'Apps', icon: 'mdi-apps', disabled: false },
                    { path: '/ad-users', title: 'Users', icon: 'mdi-chart-box', disabled: false },
                ];
            }
        }
    },
}
</script>

<style>
/* Global styles */
</style>