<template>
    <v-app>
        <v-container>
            <v-card>
                <v-card-title>Add New App</v-card-title>
                <v-card-subtitle>Please enter the details of the app:</v-card-subtitle>
                <v-card-text>
                    <v-form ref="form" v-model="addAppValid" @submit.prevent="addApp">
                        <v-text-field v-model="newApp.name" label="App Name" :rules="nameRules" @input="validateForm"
                            required></v-text-field>

                        <!-- Select Platform -->
                        <v-select v-model="newApp.platform" :items="platforms" label="Platform"
                            :rules="[v => !!v || 'Platform is required']" @change="validateForm" required></v-select>

                        <v-text-field v-model="newApp.url" label="Google Play URL" :rules="urlRules"
                            @input="validateForm" type="url" required></v-text-field>

                        <v-container fluid>
                            <v-row no-gutters>
                                <v-col v-for="(adConversion, index) in adConversions" :key="index" cols="12" md="2">
                                    <v-card class="mx-auto" max-width="344">
                                        <v-img height="200px" :src="adConversion.image" cover></v-img>
                                        <v-card-title>
                                            {{ adConversion.title }}
                                        </v-card-title>
                                        <v-card-subtitle>
                                            {{ adConversion.descriptionShort }}
                                        </v-card-subtitle>
                                        <v-card-actions>
                                            <v-checkbox v-model="adConversion.selected" color="primary"
                                                @change="validateForm"></v-checkbox>
                                            <v-spacer></v-spacer>
                                            <v-btn :icon="adConversion.expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                                                @click="toggleDescription(index)"></v-btn>
                                        </v-card-actions>
                                        <v-expand-transition>
                                            <div v-show="adConversion.expanded">
                                                <v-divider></v-divider>
                                                <v-card-text>
                                                    {{ adConversion.description }}
                                                </v-card-text>
                                            </div>
                                        </v-expand-transition>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-alert v-if="showAdTypeError" type="error">
                                Please select at least one ad type.
                            </v-alert>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn :disabled="!addAppValid || isSubmitting" @click="addApp" color="green">Add</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import axios from '@/axios';

export default {
    name: "AddApp",
    data() {
        return {
            addAppValid: false,
            isSubmitting: false,
            newApp: {
                name: '',
                platform: '',
                url: '',
                adConversions: []
            },
            platforms: ['Android', 'iOS'],
            showAdTypeError: false,
            adConversions: [
                {
                    image: require('@/assets/format-banner.svg'),
                    title: 'Banner',
                    value: 'banner', // مقدار مشخص شده
                    description: 'Banner ad units display rectangular ads that occupy a portion of an app\'s layout...',
                    expanded: false,
                    selected: false
                },
                {
                    image: require('@/assets/format-interstitial.svg'),
                    title: 'Interstitial',
                    value: 'interstitial', // مقدار مشخص شده
                    description: 'Interstitial ad units show full-page ads in your app...',
                    expanded: false,
                    selected: false
                },
                {
                    image: require('@/assets/format-native.svg'),
                    title: 'Native',
                    value: 'native', // مقدار مشخص شده
                    description: 'Native ads are ads where you can customize...',
                    expanded: false,
                    selected: false
                },
                {
                    image: require('@/assets/format-rewarded.svg'),
                    title: 'Rewarded',
                    value: 'rewarded', // مقدار مشخص شده
                    description: 'Rewarded ad units enable users to play games...',
                    expanded: false,
                    selected: false
                },
                {
                    image: require('@/assets/format-rewarded-interstitial.svg'),
                    title: 'Rewarded Interstitial',
                    value: 'rewarded-interstitial', // مقدار مشخص شده
                    description: 'Rewarded interstitial is a new type of incentivized ad format...',
                    expanded: false,
                    selected: false
                },
                {
                    image: require('@/assets/format-app-open.svg'),
                    title: 'App Open',
                    value: 'app-open', // مقدار مشخص شده
                    description: 'App open is an ad format that appears when users open or switch back...',
                    expanded: false,
                    selected: false
                }
            ],
            nameRules: [
                v => !!v || 'Name is required'
            ],
            urlRules: [
                v => !!v || 'URL is required',
                v => /^(https:\/\/play\.google\.com\/store\/apps\/details\?id=).+/.test(v) || 'Must be a valid Google Play URL'
            ]
        };
    },
    computed: {
        atLeastOneAdSelected() {
            return this.adConversions.some(ad => ad.selected);
        }
    },
    methods: {
        validateForm() {
            this.addAppValid = this.$refs.form.validate() && this.atLeastOneAdSelected;
            this.showAdTypeError = false;
        },
        ...mapActions(['showToast']),
        async addApp() {
            this.validateForm();
            if (!this.atLeastOneAdSelected) {
                this.showAdTypeError = true;
                return;
            }

            if (this.addAppValid) {
                try {
                    const selectedAdTypes = this.adConversions
                        .filter(ad => ad.selected)
                        .map(ad => ad.value);

                    const appData = { ...this.newApp, adConversions: selectedAdTypes };

                    const token = localStorage.getItem('token');
                    await axios.post('/app/add', appData, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                    });
                    this.showToast({ type: 'success', message: 'App added successfully!' });
                    this.isSubmitting = false;
                    setTimeout(() => {
                        this.$router.push('/apps');
                    }, 3000);
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.message) {
                        this.showToast({ type: 'error', message: error.response.data.message });
                    } else {
                        this.showToast({ type: 'error', message: 'An error occurred. Please try again later.' });
                    }
                }
            }
        },
        toggleDescription(index) {
            this.adConversions[index].expanded = !this.adConversions[index].expanded;
        }
    },
    created() {
        this.adConversions = this.adConversions.map(ad => ({
            ...ad,
            descriptionShort: ad.description.length > 100 ? ad.description.substring(0, 100) + '...' : ad.description,
            expanded: false
        }));
    }
};
</script>
