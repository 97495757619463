<template>
    <v-container>
        <v-card>
            <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="sortedItems"
                :items-length="totalItems" :loading="loading" item-value="name" @update:options="handleOptionsUpdate">
                <template v-slot:[`item.status`]="{ item }">
                    <v-select v-model="item.status" :items="statusOptions" hide-details
                        @update:modelValue="handleStatusChange(item)">
                        <template v-slot:selection="{ item }">
                            <span :class="['blob ', getStatusClass(item.value)]"></span>{{ item.value }}
                        </template>
                    </v-select>
                </template>
            </v-data-table-server>
        </v-card>

        <v-dialog v-model="dialog" max-width="400">
            <v-card>
                <v-card-title>
                    <span class="headline">Confirm Status Change</span>
                </v-card-title>
                <v-card-subtitle>
                    Change status of APP "{{ AppUser?.name }}" to "{{ AppUser.status }}"?
                </v-card-subtitle>

                <v-card-text v-if="AppUser.status === 'active'">
                    <v-text-field v-model="appId" label="Enter App ID" outlined clearable required></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmUpdateStatus">Confirm</v-btn>
                    <v-btn @click="cancelUpdateStatus">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import axios from '@/axios';
import { mapActions } from 'vuex';

export default {
    name: "AdminApps",
    data() {
        return {
            itemsPerPage: 5,
            currentPage: 1,
            headers: [
                { title: 'App Name', key: 'name' },
                { title: 'URL', key: 'url' },
                { title: 'AppId', key: 'appId' },
                { title: 'status', key: 'status' },
                { title: 'Owner', key: 'user_id' },
            ],
            statusOptions: ['pending', 'active', 'stop'],
            serverItems: [],
            loading: true,
            totalItems: 0,
            sortBy: [],
            updatingItem: null,
            dialog: false,
            AppUser: null,
            originalStatus: null,
            newStatus: null
        };
    },
    computed: {
        sortedItems() {
            let items = [...this.serverItems];

            items.sort((a, b) => {
                if (a.status === 'pending' && b.status !== 'pending') return -1;
                if (a.status !== 'pending' && b.status === 'pending') return 1;

                if (this.sortBy.length) {
                    const key = this.sortBy[0].key;
                    const order = this.sortBy[0].order;
                    const aValue = a[key];
                    const bValue = b[key];
                    if (aValue < bValue) return order === 'asc' ? -1 : 1;
                    if (aValue > bValue) return order === 'asc' ? 1 : -1;
                }
                return 0;
            });

            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return items.slice(start, end);
        }
    },
    methods: {
        getStatusClass(status) {
            console.log(status)
            switch (status) {
                case 'pending':
                    return 'orange';
                case 'active':
                    return 'green';
                case 'stop':
                    return 'red';
                default:
                    return '';
            }
        },
        ...mapActions(['showToast']),
        async loadItems() {
            this.loading = true;

            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('/app/list', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        page: this.currentPage,
                        itemsPerPage: this.itemsPerPage,
                        sortBy: this.sortBy.length ? this.sortBy[0].key : '',
                        sortOrder: this.sortBy.length ? this.sortBy[0].order : ''
                    }
                });

                this.serverItems = response.data.apps;
                this.totalItems = response.data.apps.length;
            } catch (error) {
                console.error('Failed to fetch apps:', error);
            } finally {
                this.loading = false;
            }
        },
        handleOptionsUpdate(options) {
            if (
                options.page !== this.currentPage ||
                options.itemsPerPage !== this.itemsPerPage ||
                JSON.stringify(options.sortBy) !== JSON.stringify(this.sortBy)
            ) {
                this.loadItems(options);
            }
        },
        async updateStatus(item) {
            this.updatingItem = item.id;

            try {
                const token = localStorage.getItem('token');
                if (!token) throw new Error('No token found');

                const response = await axios.post('/app/upStatus', {
                    id: item.id,
                    status: item.status,
                    appid: this.appId
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    this.showToast({ type: 'success', message: 'Status updated successfully' });

                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    this.showToast({ type: 'error', message: error.response.data.message });
                } else {
                    this.showToast({ type: 'error', message: 'An error occurred. Please try again later.' });
                }
            } finally {
                setTimeout(() => {
                    this.updatingItem = null;
                }, 1000);
            }
        },
        handleStatusChange(item) {
            this.AppUser = item;
            this.dialog = true;
        },
        confirmUpdateStatus() {
            if (this.AppUser.status === 'active' && !this.appId) {
                this.showToast({ type: 'error', message: 'Please enter the App ID.' });
                return;
            }
            this.updateStatus(this.AppUser);
            this.dialog = false;
        },
        cancelUpdateStatus() {
            this.appId = null
            this.dialog = false;
            location.reload();
        }
    },
    mounted() {
        this.loadItems({
            page: this.currentPage,
            itemsPerPage: this.itemsPerPage,
            sortBy: this.sortBy
        });
    }
};
</script>


<style scoped>
.blob {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.blob.orange {
    background: rgba(255, 121, 63, 1);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
    animation: pulse-orange 2s infinite;
}

@keyframes pulse-orange {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
    }
}

.blob.red {
    background: rgba(255, 82, 82, 1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

.blob.green {
    background: rgba(51, 217, 178, 1);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
    animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
    }
}
</style>
