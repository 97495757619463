<template>
    <div class="login-container">
        <v-container fluid fill-height class="d-flex align-center justify-center">
            <v-card class="login-card pa-6">
                <v-card-title class="headline text-center">
                    پنل هوشمند ادموب - Smart AdMob Panel
                </v-card-title>
                <v-card-subtitle class="text-center">
                    Please login to continue
                </v-card-subtitle>
                
                <!-- فرم ورود با ایمیل -->
                <v-card-text>
                    <v-text-field label="Email" v-model="email" />
                    <v-text-field label="Password" type="password" v-model="password" />
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn @click="loginWithEmail" variant="elevated" class="email-login-btn">
                        Login with Email
                    </v-btn>
                </v-card-actions>

                <v-divider class="my-4"></v-divider>

                <!-- دکمه ورود با گوگل -->
                <v-card-actions class="d-flex justify-center">
                    <v-btn @click="loginWithGoogle" variant="elevated" class="google-login-btn">
                        <div class="google-icon-container">
                            <img :src="googleLogo" alt="Google Logo" class="google-icon" />
                        </div>
                        Login Using Google
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import { googleSdkLoaded } from "vue3-google-login";
import axios from '@/axios';
import { mapActions } from 'vuex';

export default {
    name: "LoginPage",
    data() {
        return {
            googleLogo: require('@/assets/Google.svg'),
            email: '',
            password: ''
        };
    },
    methods: {
        ...mapActions(['login']),
        
        loginWithGoogle() {
            googleSdkLoaded(google => {
                google.accounts.oauth2
                    .initCodeClient({
                        client_id: "905669663086-2k14urd7dqd7n8cr8iaibudvvs4bsob6.apps.googleusercontent.com",
                        scope: "email profile openid",
                        redirect_uri: "http://localhost:3000",
                        callback: response => {
                            if (response.code) {
                                this.sendGoogleCodeToBackend(response.code);
                            }
                        }
                    })
                    .requestCode();
            });
        },

        loginWithEmail() {
            const payload = {
                email: this.email,
                password: this.password
            };
            this.sendEmailToBackend(payload);
        },

        async sendGoogleCodeToBackend(code) {
            try {
                const headers = { Authorization: code };
                const response = await axios.post("/auth/google", null, { headers });
                const { token, userDetails } = response.data;
                this.$store.dispatch('login', { token, userDetails });
                this.$router.push('/');
            } catch (error) {
                console.error("Failed to send Google authorization code:", error);
            }
        },

        async sendEmailToBackend(payload) {
            try {
                const response = await axios.post("/auth/email", payload);
                const { token, userDetails } = response.data;
                this.$store.dispatch('login', { token, userDetails });
                this.$router.push('/');
            } catch (error) {
                console.error("Failed to authenticate with email:", error);
            }
        }
    }
};
</script>

<style scoped>
.google-login-btn {
    width: 100%;
    font-weight: bold;
    background-color: #fff;
    color: #000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    text-transform: none;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.google-login-btn:hover {
    background-color: #4285F4;
    color: #fff;
}

.google-icon-container {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
    margin-right: 8px;
    transition: background-color 0.3s ease;
}

.google-icon-container img {
    width: 16px;
    height: 16px;
}

.login-container {
    background-image: url('@/assets/login.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* استایل‌ها برای دکمه ورود با ایمیل */
.email-login-btn {
    width: 100%;
    font-weight: bold;
    background-color: #2196F3;
    color: #fff;
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.email-login-btn:hover {
    background-color: #1E88E5;
}

/* سایر استایل‌ها همانند قبل */
</style>
