<template>
    <v-row class="pt-3 px-2 elevation-1 bg-white top-0 center overflow-x-auto flex-nowrap"
        style="z-index:1000; white-space: nowrap;">
        <v-col class="d-flex" cols="auto">
            <v-label class="text-h6 text-gray-600 font-weight-bold">Filters:</v-label>
        </v-col>
        <v-col class="d-flex" cols="auto">
            <div class="d-flex">
                <v-col v-for="(value, filter) in filters" :key="filter" class="px-1">
                    <v-menu v-model="value.menu" activator="parent" offset-y :close-on-content-click="false">
                        <template v-slot:activator="{ attrs }">
                            <v-btn v-bind="attrs" class="mx-2 text-gray-600 text-caption"
                                :border="value.isActive ? 'info sm opacity-100' : ''" outlined rounded>
                                {{ value.title }}
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="headline">{{ filter }}</v-card-title>
                            <component :ComType="filter" :is="getComponentForFilter(filter)"
                                v-bind="getComponentProps(filter)"
                                @update:selectedDates="updateComponentData('update:selectedDates', $event, filter)"
                                @update:selectionapplied="updateComponentData('update:selectionapplied', $event, filter)" />
                        </v-card>
                    </v-menu>
                </v-col>

            </div>
        </v-col>
    </v-row>
    <v-container>
        <v-row class="mt-5">
            <v-col cols="6">
                <ReportChart
                    :titleKey="{ title: 'Est Earning', key: 'estimated_earnings', filters: this.getActiveFilters() }" />
            </v-col>
            <v-col cols="6">
                <ReportChart :titleKey="{ title: 'Requests', key: 'ad_requests', filters: this.getActiveFilters() }" />
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="4">
                <ReportChart
                    :titleKey="{ title: 'Impressions', key: 'impressions', filters: this.getActiveFilters() }" />
            </v-col>
            <v-col cols="4">
                <ReportChart :titleKey="{ title: 'Match Rate', key: 'match_rate', filters: this.getActiveFilters() }" />
            </v-col>
            <v-col cols="4">
                <ReportChart
                    :titleKey="{ title: 'Observed eCPM', key: 'observed_ecpm', filters: this.getActiveFilters() }" />
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="12">
                <v-card>
                    <v-card-title>Metrics and Dimensions</v-card-title>
                    <v-row>
                        <v-col cols="10">
                            <v-data-table-server v-model:items-per-page="itemsPerPage" v-model:page="currentPage"
                                :headers="activeHeaders" :items="tableData" :items-length="totalItems"
                                :loading="loading" @update:page="onPageChange"
                                @update:items-per-page="onItemsPerPageChange" :header-props="{ class: 'border-b-lg' }">

                                <template v-slot:[`item.impressions`]="{ item }">
                                    {{ Number.parseFloat(item.impressions).toLocaleString(undefined, {
                                        minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }) }}
                                </template>
                                <template v-slot:[`item.ad_requests`]="{ item }">
                                    {{ Number.parseFloat(item.ad_requests).toLocaleString(undefined, {
                                        minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }) }}
                                </template>
                                <template v-slot:[`item.estimated_earnings`]="{ item }">
                                    ${{ Number.parseFloat(item.estimated_earnings).toFixed(2) }}
                                </template>
                                <template v-slot:[`item.match_rate`]="{ item }">
                                    {{ Number.parseFloat(item.match_rate).toFixed(2) }}%
                                </template>
                                <template v-slot:[`item.observed_ecpm`]="{ item }">
                                    %{{ Number.parseFloat(item.observed_ecpm).toFixed(2) }}
                                </template>
                            </v-data-table-server>

                        </v-col>
                        <v-col cols="2">
                            <v-list>
                                <v-list-item class="font-weight-bold">Dimensions</v-list-item>
                                <v-list-item class="border-sm py-4 border-radius: 8px;"
                                    v-for="(item, index) in dimensions" :key="index" :title="item"
                                    :value="item.toLowerCase()" @click="toggleColumn(item, 'dimension')"
                                    :class="{ 'active-dimensions': selectedColumns.includes(item) }">
                                </v-list-item>
                                <v-list-item class="border-sm py-4 border-radius: 8px;" v-if="dimensions.length > 6"
                                    @click="toggleDimensions">
                                    <v-list-item>
                                        <v-icon gray>mdi-dots-horizontal</v-icon>
                                    </v-list-item>
                                </v-list-item>
                            </v-list>

                            <v-list>
                                <v-list-item class="font-weight-bold">Metrics</v-list-item>
                                <v-list-item class="border-sm py-4 border-radius: 8px;" v-for="(item, index) in metrics"
                                    :key="index" :title="item" :value="item.toLowerCase()"
                                    @click="toggleColumn(item, 'metric')"
                                    :class="{ 'active-metrics': selectedColumns.includes(item) }">
                                </v-list-item>
                                <v-list-item class="border-sm py-4 border-radius: 8px;" v-if="metrics.length > 6"
                                    @click="toggleMetrics">
                                    <v-list-item>
                                        <v-icon gray>mdi-dots-horizontal</v-icon>
                                    </v-list-item>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from '@/axios';
import { mapActions } from 'vuex';
import DateRangePicker from "../components/DateRangePicker.vue";
import TagCard from "../components/TagCard.vue";
import ReportChart from "../components/ReportChart.vue";
export default {
    name: "MainReports",
    components: {
        DateRangePicker,
        TagCard,
        ReportChart
    },
    data() {
        return {
            filters: {
                "Date Range": { isActive: false, title: "Date Range", menu: false, selectedValue: null },
                "App": { isActive: false, menu: false, title: "App", selectedValue: null },
                "Ad Unit": { isActive: false, menu: false, title: "Ad Unit", selectedValue: null },
                "Country": { isActive: false, menu: false, title: "Country", selectedValue: null },
                "Ad Source": { isActive: false, menu: false, title: "Ad Source", selectedValue: null },
                "Mediation Group": { isActive: false, menu: false, title: "Mediation Group", selectedValue: null },
                "Platform": { isActive: false, menu: false, title: "Platform", selectedValue: null },
            },

            colomn: [''],
            chartOptions: {
            },
            showAllDimensions: false,
            showAllMetrics: false,
            tableData: [],
            pagination: { page: 1, rowsPerPage: 20 },
            loading: false,
            currentPage: 1,
            itemsPerPage: 20,
            totalItems: 0,
            sortBy: [],
            dialog: false,

            allHeaders: [
                { title: 'App', key: 'app_name' },
                { title: 'Ad Unit', key: 'ad_unit_name' },
                { title: 'Country', key: 'country' },
                { title: 'Date', key: 'date' },
                { title: 'Requests', key: 'ad_requests' },
                { title: 'Clicks', key: 'clicks' },
                { title: 'Est Earning', key: 'estimated_earnings' },
                { title: 'Impressions', key: 'impressions' },
                { title: 'Match Rate', key: 'match_rate' },
                { title: 'Observed eCPM', key: 'observed_ecpm' },
                { title: 'Ad Source', key: 'ad_source_dis' },
                { title: 'Format', key: 'platform' },
                { title: 'Mediation Group', key: 'mediation_group_dis' },
            ],
            dimensions: ["Date", "Ad Source", "App", "Ad Unit", "Country", "Format"],
            metrics: ["Est Earning", "Requests", "Impressions", "Match Rate", "Observed eCPM"],
            selectedColumns: ["Date", "Requests", "Impressions", "Est Earning"],
            selectedDates: [],
            dateText: '',


        };
    },
    computed: {

        activeHeaders() {
            return this.allHeaders
                .filter(header => this.selectedColumns.includes(header.title))
                .map(header => ({
                    title: header.title,
                    key: header.key,
                    headerProps: { class: this.getHeaderClass(header.title) }
                }));
        },

        visibleDimensions() {
            return this.showAllDimensions ? this.dimensions : this.dimensions.slice(0, 6);
        },
        visibleMetrics() {
            return this.showAllMetrics ? this.metrics : this.metrics.slice(0, 6);
        },
    },
    mounted() {
        this.fetchReportData();
    },
    methods: {
        getActiveFilters() {
            return Object.keys(this.filters)
                .filter(key => this.filters[key].isActive)
                .reduce((acc, key) => {
                    acc[key] = this.filters[key].selectedValue;
                    return acc;
                }, {});
        },
        getHeaderClass(headerText) {
            if (this.dimensions.includes(headerText)) {
                return 'active-col-dimensions';
            } else if (this.metrics.includes(headerText)) {
                return 'active-col-metrics';
            }
            return '';
        },
        ...mapActions(['showToast']),
        updateComponentData(eventName, data, filter) {
            switch (eventName) {
                case 'update:selectedDates':
                    if (data.length >= 2) {
                        this.filters[filter].isActive = true;
                        this.filters[filter].selectedValue = [data[0], data[data.length - 1]];
                        this.filters[filter].title = `Date Range: ${this.formatDate(data[0])} - ${this.formatDate(data[data.length - 1])}`;
                    } else {
                        this.filters[filter].isActive = false;
                        this.filters[filter].selectedValue = null;
                        this.filters[filter].title = 'Date Range';
                    }
                    this.filters[filter].menu = false;
                    this.fetchReportData();
                    break;
                case 'update:selectionapplied':
                    if (this.filters[filter].selectedValue == []) {
                        this.filters[filter].isActive = false;
                        this.filters[filter].title = `${this.filters[filter].title}`
                    } else {
                        this.filters[filter].title = `${this.filters[filter].title}: ${data.map(item => item.name).join('-')};`;
                        this.filters[filter].isActive = true;
                        this.filters[filter].selectedValue = data.map(item => item.id);
                    }
                    this.filters[filter].menu = false;
                    this.fetchReportData();
                    break;
            }
        },

        formatDate(date) {
            const d = new Date(date);
            const day = String(d.getDate()).padStart(2, '0');
            const month = String(d.getMonth() + 1).padStart(2, '0');
            const year = d.getFullYear().toString();
            return `${year}/${month}/${day}`;
        },
        getComponentForFilter(filter) {
            switch (filter) {
                case 'Date Range':
                    return 'DateRangePicker';
                case 'App':
                case 'Ad Unit':
                case 'Country':
                case 'Format':
                case 'Ad Source':
                case 'Mediation Group':
                case 'Service Type':
                case 'Platform':
                case 'Service Restriction':
                case 'ATT Consent Status':
                    return 'TagCard';
                default:
                    return null;
            }
        },
        getComponentProps(filter) {
            switch (filter) {
                case 'Date Range':
                    return {
                        selectedDates: this.selectedDates,
                        dateText: this.dateText,
                        menu: this.filters[filter].menu,
                    }
                default:
                    return null;
            }
        },
        async fetchReportData() {
            this.loading = true;
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('/report', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        page: this.currentPage,
                        itemsPerPage: this.itemsPerPage,
                        sortBy: this.sortBy.length ? this.sortBy[0].key : '',
                        sortOrder: this.sortBy.length ? this.sortBy[0].order : '',
                        dimensions: this.selectedColumns.filter(column => this.dimensions.includes(column)),
                        metrics: this.selectedColumns.filter(column => this.metrics.includes(column)),
                        filters: this.getActiveFilters(),
                    }
                })
                if (response.status === 200) {
                    const reportData = response.data;
                    this.tableData = reportData.items;
                    this.totalItems = reportData.totalItems;
                    this.showToast({ type: 'success', message: 'Data loaded successfully!' });
                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    this.showToast({ type: 'error', message: error.response.data.message });
                } else {
                    this.showToast({ type: 'error', message: 'An error occurred. Please try again later.' });
                }
            } finally {
                this.loading = false;
            }
        },
        onPageChange(newPage) {
            this.currentPage = newPage;
            this.fetchReportData();
        },

        onItemsPerPageChange(newItemsPerPage) {
            this.itemsPerPage = newItemsPerPage;
            this.fetchReportData();
        },
        toggleDimensions() {
            this.showAllDimensions = !this.showAllDimensions;
        },
        toggleMetrics() {
            this.showAllMetrics = !this.showAllMetrics;
        },
        toggleColumn(item) {
            if (item === "Date" || item === "Est Earning") {
                return;
            }

            const index = this.selectedColumns.indexOf(item);
            if (index === -1) {
                this.selectedColumns.push(item);
            } else {
                this.selectedColumns.splice(index, 1);
            }
            this.fetchReportData();
        },
    },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.active-metrics {
    border-left: 5px solid #7baaf7 !important;
}

.active-dimensions {
    border-left: 5px solid #81c784 !important;
}

:v-deep .v-data-table>div>table {
    border-spacing: 5px 0 !important;
}

.xxx {
    position: fixed;
    top: 64px;
    z-index: 999;
}
</style>
<style>
.active-col-metrics {
    border-bottom: 5px solid #7baaf7 !important;
}

.active-col-dimensions {
    border-bottom: 5px solid #81c784 !important;
}
</style>