<template>
    <v-card class="pa-4">
        <!-- بخش بالایی -->
        <v-row justify="space-between" class="align-center">
            <v-col>
                <v-text-field v-model="search" label="Search" dense outlined clearable hide-details></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="pa-0" cols="12">
                <v-checkbox v-model="selectAll" label="Select All" @change="toggleSelectAll"></v-checkbox>
            </v-col>

            <v-col class="pa-0" cols="12" style="max-height: 300px; overflow-y: auto;">
                <v-list>
                    <v-list-item v-for="(item, index) in filteredItems" :key="index" @click="toggleItemSelection(item)">
                        <template v-slot:prepend>
                            <v-list-item-action start>
                                <v-checkbox v-model="filters[this.type].selectedItems"
                                    :value="{name: item[this.filters[this.type].title],id: item[this.filters[this.type].id]}" hide-details></v-checkbox>
                            </v-list-item-action>
                        </template>
                        <v-list-item-title>{{ item[filters[this.type].title] }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-col>

        </v-row>

        <v-divider></v-divider>

        <v-row justify="center" class="mt-4">
            <v-btn color="primary" @click="applySelection">Apply</v-btn>
        </v-row>
    </v-card>
</template>

<script>
import axios from '@/axios';

export default {
    props: {
        ComType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            search: '',
            selectAll: false,
            items: [],
            type: null,
            filters: {
                'App': { db: 'App ID', title: 'app_name', id: 'app_id', selectedItems: [] },
                'Ad Unit': { db: "Ad Unit ID", title: 'ad_unit_name', id: 'ad_unit_id', selectedItems: [] },
                'Country': { db: "Country", title: 'country', id: 'country', selectedItems: [] },
                "Ad Source": { db: "Ad Source ID", title: 'ad_source_dis', id: 'ad_source', selectedItems: [] },
                "Mediation Group": { db: "Mediation Group ID", title: 'mediation_group_dis', id: 'mediation_group', selectedItems: [] },
                "Platform": { db: "Platform", title: 'platform', id: 'platform', selectedItems: [] },
            }
        };
    },
    computed: {
        filteredItems() {
            const filter = this.filters[this.type];
            const titleKey = filter.title;
            return this.items.filter((item) => {
                const titleValue = item[titleKey] || '';
                return titleValue.toString().toLowerCase().includes(this.search.toLowerCase());
            });
        },
    },
    watch: {
        ComType: {
            immediate: true,
            handler(newType) {
                this.type = newType;
                this.fetchReportData(newType);
            },
        }
    },
    methods: {
        async fetchReportData(type) {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('/report', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        metrics: ['Est Earning'],
                        dimensions: [type, this.filters[type].db],
                    },
                });
                if (response.status === 200) {
                    this.items = response.data.items;
                }
            } catch (error) {
                console.error('Error fetching report data:', error);
            }
        },
        toggleSelectAll() {
            if (this.selectAll) {
                this.filters[this.type].selectedItems = this.items.map(item => item[this.filters[this.type].id]);
            } else {
                this.filters[this.type].selectedItems = [];
            }
        },
        toggleItemSelection(item) {
            const index = this.filters[this.type].selectedItems.findIndex(selected => selected.id === item[this.filters[this.type].id]);
            if (index === -1) {
                this.filters[this.type].selectedItems.push({
                    name: item[this.filters[this.type].title],
                    id: item[this.filters[this.type].id]
                });
            } else {
                this.filters[this.type].selectedItems.splice(index, 1);
            }
        },
        applySelection() {
            this.$emit('update:selectionapplied', this.filters[this.type].selectedItems);
        }
    },
};
</script>

<style scoped>
.v-list-item {
    cursor: pointer;
}
</style>
