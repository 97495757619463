import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import PanelHome from '../views/MainDashboard.vue';
import PanelApps from '../views/MainApps.vue';
import PanelProfile from '../views/MainProfile.vue';
import PanelReports from '../views/MainReports.vue';
import PanelAdApps from '../views/AdminApps.vue';
import PanelAdUsers from '../views/AdminUsers.vue';
import PanelAdHome from '../views/AdminDashboard.vue';
import AddApp from '../views/AddApp.vue';

const routes = [
  { path: '/', component: PanelHome, name: 'MainDashboard', meta: { title: 'Home' } },
  { path: '/apps', component: PanelApps, name: 'MainApps', meta: { title: 'Apps' } },
  { path: '/profile', component: PanelProfile, name: 'MainProfile', meta: { title: 'Profile' } },
  { path: '/reports', component: PanelReports, name: 'MainReports', meta: { title: 'Reports' } },
  { path: '/ad-home', component: PanelAdHome, name: 'AdminHome', meta: { title: 'Home' } },
  { path: '/ad-apps', component: PanelAdApps, name: 'AdminApps', meta: { title: 'Apps' } },
  { path: '/ad-users', component: PanelAdUsers, name: 'AdminUsers', meta: { title: 'Users' } },
  { path: '/add-app', name: 'AddApp', component: AddApp, meta: { title: 'Add App' } },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = 'AdMob - ' + to.meta.title;
  }

  const userDetails = store.getters.userDetails;
  const isAuthenticated = !!userDetails;
  const userStatus = userDetails ? userDetails.status : null;

  if (isAuthenticated) {
    if (userStatus !== 'Accepted' && to.path !== '/profile') {
      next('/profile');
    } else {
      next();
    }
  } else {
    if (to.path !== '/') {
      next('/');
    } else {
      next();
    }
  }
});


export default router;
