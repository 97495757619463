<template>
    <v-container>
        <v-card>
            <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="sortedItems"
                :items-length="totalItems" :loading="loading" item-value="name" @update:options="handleOptionsUpdate">
                <template v-slot:[`item.go_picture`]="{ item }">
                    <v-avatar>
                        <img :src="getCachedImage(item.go_email, item.go_picture)" alt="User Picture" width="50" />
                    </v-avatar>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-select v-model="item.status" :items="statusOptions" hide-details
                        @update:modelValue="handleStatusChange(item)">
                        <template v-slot:selection="{ item }">
                            <v-icon :icon="statusIcon(item.value)" :class="statusIconClass(item.value)"></v-icon>
                        </template>
                    </v-select>
                </template>
            </v-data-table-server>
        </v-card>

        <v-dialog v-model="dialog" max-width="400">
            <v-card>
                <v-card-title>
                    <span class="headline">Confirm Status Change</span>
                </v-card-title>
                <v-card-subtitle>
                    Change status of user "{{ selectedUser?.first_name }} {{ selectedUser?.last_name }}" to "{{
                        selectedUser.status }}"?
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="confirmUpdateStatus">Confirm</v-btn>
                    <v-btn @click="cancelUpdateStatus">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import axios from '@/axios';
import { mapActions } from 'vuex';

export default {
    name: "AdminUsers",
    data() {
        return {
            itemsPerPage: 5,
            currentPage: 1,
            headers: [
                { title: 'pic', key: 'go_picture' },
                { title: 'First Name', key: 'first_name' },
                { title: 'Last Name', key: 'last_name' },
                { title: 'Email', key: 'go_email' },
                { title: 'Mobile', key: 'mobile' },
                { title: 'Telegram ID', key: 'telegram_id' },
                { title: 'App URL', key: 'app_url' },
                { title: 'Status', key: 'status' },
            ],
            statusOptions: ['Accepted', 'Rejected', 'Requested'],
            serverItems: [],
            loading: true,
            totalItems: 0,
            sortBy: [],
            updatingItem: null,
            dialog: false,
            selectedUser: null,
            originalStatus: null,
            newStatus: null
        };
    },
    computed: {
        sortedItems() {
            let items = [...this.serverItems];

            items.sort((a, b) => {
                if (a.status === 'Requested' && b.status !== 'Requested') return -1;
                if (a.status !== 'Requested' && b.status === 'Requested') return 1;

                if (this.sortBy.length) {
                    const key = this.sortBy[0].key;
                    const order = this.sortBy[0].order;
                    const aValue = a[key];
                    const bValue = b[key];
                    if (aValue < bValue) return order === 'asc' ? -1 : 1;
                    if (aValue > bValue) return order === 'asc' ? 1 : -1;
                }
                return 0;
            });

            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return items.slice(start, end);
        }
    },
    methods: {
        getCachedImage(email, url) {
            const cachedImage = localStorage.getItem(`userPic_${email}`);

            if (cachedImage) {
                return cachedImage;
            } else {
                const image = new Image();
                image.src = url;
                image.onload = () => {
                    localStorage.setItem(`userPic_${email}`, url);
                };
                return url;
            }
        },
        ...mapActions(['showToast']),
        statusIcon(status) {
            switch (status) {
                case 'Accepted':
                    return 'mdi-check-circle';
                case 'Rejected':
                    return 'mdi-close-circle';
                case 'Requested':
                    return 'mdi-arrow-top-right-bold-box';
                case 'new':
                    return 'mdi-alert-decagram';
                default:
                    return '';
            }
        },
        statusIconClass(status) {
            switch (status) {
                case 'Accepted':
                    return 'text-success';
                case 'Rejected':
                    return 'text-red';
                case 'Requested':
                    return 'text-orange';
                case 'new':
                    return 'text-blue';
                default:
                    return '';
            }
        },
        async loadItems({ page, itemsPerPage: pageItemsPerPage, sortBy: sortOptions }) {
            this.loading = true;
            this.currentPage = page;
            this.itemsPerPage = pageItemsPerPage;
            this.sortBy = sortOptions;

            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('/user/list', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    params: {
                        page,
                        itemsPerPage: pageItemsPerPage,
                        sortBy: sortOptions.length ? sortOptions[0].key : '',
                        sortOrder: sortOptions.length ? sortOptions[0].order : ''
                    }
                });

                this.serverItems = response.data.items;
                this.totalItems = response.data.total;
            } catch (error) {
                console.error('Failed to fetch users:', error);
            } finally {
                this.loading = false;
            }
        },
        handleOptionsUpdate(options) {
            if (
                options.page !== this.currentPage ||
                options.itemsPerPage !== this.itemsPerPage ||
                JSON.stringify(options.sortBy) !== JSON.stringify(this.sortBy)
            ) {
                this.loadItems(options);
            }
        },
        async updateStatus(item) {
            this.updatingItem = item.id;

            try {
                const token = localStorage.getItem('token');
                if (!token) throw new Error('No token found');

                const response = await axios.post('/user/update', {
                    id: item.id,
                    status: item.status
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if (response.status === 200) {
                    this.showToast({ type: 'success', message: 'Status updated successfully' });
                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    this.showToast({ type: 'error', message: error.response.data.message });
                } else {
                    this.showToast({ type: 'error', message: 'An error occurred. Please try again later.' });
                }
            } finally {
                setTimeout(() => {
                    this.updatingItem = null;
                }, 1000);
            }
        },
        handleStatusChange(item) {
            this.selectedUser = item;
            this.dialog = true;
        },
        confirmUpdateStatus() {
            this.updateStatus(this.selectedUser);
            this.dialog = false;
        },
        cancelUpdateStatus() {
            this.dialog = false;
            location.reload();
        }
    },
    mounted() {
        this.loadItems({
            page: this.currentPage,
            itemsPerPage: this.itemsPerPage,
            sortBy: this.sortBy
        });
    }
};
</script>


<style scoped></style>
