// main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createVuetify } from 'vuetify';
import 'vuetify/styles';
import { aliases, mdi } from 'vuetify/iconsets/mdi';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { googleSdkLoaded } from 'vue3-google-login';
import store from './store/index.js';
import '@mdi/font/css/materialdesignicons.css'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import axios from './axios';
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});

googleSdkLoaded(() => {
  console.log("Google SDK Loaded");
});
const app = createApp(App)
app.config.globalProperties.$axios = axios;
app.use(router)
  .use(vuetify)
  .use(store)
  .use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 15,
    newestOnTop: true,
    position: "top-right",
    timeout: 5000,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 1,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: false,
    icon: true,
    rtl: false
  }).mount('#app');
