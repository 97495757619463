<!-- C:\Users\Hi\Desktop\Admob-panel\client\src\App.vue -->
<template>
  <v-app>
    <v-layout v-if="isAuthenticated">
      <TheNav />
      <TheBar />
      <v-main>
        <!-- <v-container> -->
          <div v-if="userDetails && userDetails.status === 'Requested'" class="d-flex justify-start">
            <v-alert density="compact"
              text="Your request is being processed and your account has not been activated yet! Please be patient."
              title="one more" type="warning"></v-alert>
          </div>
          <div v-if="userDetails && userDetails.status === 'Rejected'" class="d-flex justify-start">
            <v-alert density="compact"
              text="ops!, unfortunately, your request has been rejected. I hope next time..."
              title="next time" type="error"></v-alert>
          </div>
          <router-view />
        <!-- </v-container> -->
      </v-main>
    </v-layout>
    <LoginPage v-else />
  </v-app>
</template>


<script>
import { mapGetters } from 'vuex';
import LoginPage from './components/LoginPage.vue';
import TheNav from './components/TheNav.vue';
import TheBar from './components/TheBar.vue';
export default {
  name: 'App',
  components: {
    TheNav,
    TheBar,
    LoginPage
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapGetters(['userDetails']),
  },
  created() {
    this.$store.dispatch('initializeStore');
  },
};
</script>
<style>
::-webkit-scrollbar {
  height: 7px;
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.scrollable {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1; 
}
</style>