<template>
  <v-container>
    <!-- Top Earnings Section -->
    <v-row>
      <v-col>
        <v-card title="Est. AdMob Network earnings" color="indigo" class="pa-4">
          <v-row>
            <v-col>
              <v-card-subtitle>Today so far</v-card-subtitle>
              <v-card-text class="text-h4">US${{ todayEarnings }}</v-card-text>
            </v-col>
            <v-col>
              <v-card-subtitle>Yesterday</v-card-subtitle>
              <v-card-text class="text-h4">US${{ yesterdayEarnings }}</v-card-text>
            </v-col>
            <v-col>
              <v-card-subtitle>This month so far</v-card-subtitle>
              <v-card-text class="text-h4">US${{ thisMonthEarnings }}</v-card-text>
            </v-col>
            <v-col>
              <v-card-subtitle>Last month</v-card-subtitle>
              <v-card-text class="text-h4">US${{ lastMonthEarnings }}</v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <!-- Date Picker Section -->
    <v-row style="max-width: 300px;">
      <v-col>
        <v-menu v-model="menu" activator="parent" :close-on-content-click="false" transition="scale-transition"
          offset-y>
          <template v-slot:activator="{ props }">
            <v-text-field v-model="dateText" label="Select Date Range" prepend-icon="mdi-calendar-blank" readonly
              v-bind="props"></v-text-field>
          </template>
          <v-card class="pa-3">
            <date-range-picker v-model:selectedDates="selectedDates" v-model:dateText="dateText" v-model:menu="menu" />
          </v-card>
        </v-menu>
      </v-col>
    </v-row>

    <!-- Performance Sections -->
    <v-row>
      <!-- AdMob Network Performance -->
      <v-col>
        <v-card variant="tonal" class="pa-4">
          <v-card-title>AdMob Network performance</v-card-title>
          <v-row>
            <v-col>
              <v-card-subtitle>Est earning</v-card-subtitle>
              <v-card-text>US$ {{ totalEarnings }}</v-card-text>
            </v-col>
            <v-col>
              <v-card-subtitle>Requests</v-card-subtitle>
              <v-card-text>{{ totalRequests }}</v-card-text>
            </v-col>
            <v-col>
              <v-card-subtitle>Impr.</v-card-subtitle>
              <v-card-text>{{ totalImpressions }}</v-card-text>
            </v-col>
            <v-col>
              <v-card-subtitle>Match rate</v-card-subtitle>
              <v-card-text>{{ matchRate }}%</v-card-text>
            </v-col>
            <v-col>
              <v-card-subtitle>eCPM</v-card-subtitle>
              <v-card-text>US$ {{ eCPM }}</v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- App Performance -->
      <v-col>
        <v-card variant="tonal" class="pa-4">
          <v-card-title>App performance</v-card-title>
          <v-table>
            <thead>
              <tr>
                <th class="text-left">App</th>
                <th class="text-left">Est earning</th>
                <th class="text-left">Impr.</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="app in apps" :key="app.name">
                <td>{{ app.name }}</td>
                <td>US$ {{ app.earnings }}</td>
                <td>{{ app.impressions }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '@/axios';
import DateRangePicker from "../components/DateRangePicker.vue";

export default {
  name: "MainDashboard",
  components: {
    DateRangePicker,
  },
  data() {
    return {
      menu: false,
      dateText: '',
      selectedDates: [],
      todayEarnings: 0,
      yesterdayEarnings: 0,
      thisMonthEarnings: 0,
      lastMonthEarnings: 0,
      totalEarnings: 0,
      totalRequests: 0,
      totalImpressions: 0,
      matchRate: 0,
      eCPM: 0,
      apps: [
        { name: "App 1", earnings: 45.32, impressions: 23000 },
        { name: "App 2", earnings: 78.21, impressions: 18000 },
        { name: "App 3", earnings: 12.34, impressions: 12000 },
      ],
    };
  },
  methods: {
    async fetchReportForDateRange(startDate, endDate) {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('/report', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            dimensions: ['Date'],
            metrics: ['Est Earning'],
            filters: {
              'Date Range': [startDate, endDate],
            },
          },
        });

        if (response.status === 200) {
          const reportData = response.data.items;
          return reportData.length ? reportData[0].estimated_earnings : 0;
        }
      } catch (error) {
        console.error('Error loading data:', error);
        return 0;
      }
    },
    async fetchDashboardReports() {
      const today = new Date().toISOString();
      const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString();
      const startOfMonth = new Date(new Date().setDate(1)).toISOString();
      const lastMonthStart = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).toISOString();
      const lastMonthEnd = new Date(new Date().getFullYear(), new Date().getMonth(), 0).toISOString();

      this.todayEarnings = await this.fetchReportForDateRange(today, today);
      this.yesterdayEarnings = await this.fetchReportForDateRange(yesterday, yesterday);
      this.thisMonthEarnings = await this.fetchReportForDateRange(startOfMonth, today);
      this.lastMonthEarnings = await this.fetchReportForDateRange(lastMonthStart, lastMonthEnd);
    },
  },
  mounted() {
    this.fetchDashboardReports();
  },
};
</script>

<style scoped></style>
